<template>
  <div>
    <h1>Configurações Pessoais</h1>

    <v-btn
      color="error"
      @click="$router.back()"
      class="ma-2"
    >
      <v-icon>
        mdi-keyboard-return
      </v-icon>
    </v-btn>
    <colors />
  </div>
</template>

<script>

import Colors from './Colors.vue'

  export default {
  components: { Colors },
    data() {
      return {
        colors: {
          ok: '#33A54EFF',
          atrasadas: '#EC4040FF',
          retido: '#E76DF0FF',
          hoje: '#E1EC3DFF',
          ocorrencia: '#E76C35FF',
          rota: '#3D9EECFF',
        }
      }
    },
  }
</script>

<style scoped>

</style>