<template>
  <div>
    <template>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>
            Cores
          </v-expansion-panel-header>
          <v-expansion-panel-content>

            <v-btn class="my-2" color="primary" block @click="save">Salvar</v-btn>

            <div class="d-flex flex-wrap justify-space-between">

              <v-card>
                <v-card-title>
                  Entregas OK
                </v-card-title>
                <v-card-text>
                    <v-color-picker
                  dot-size="25"
                  swatches-max-height="200"
                  v-model="colors.ok"
                  elevation="10"
                  mode="hexa"
                ></v-color-picker>
                </v-card-text>
              </v-card>

              <v-card>
                <v-card-title>
                  Entregas Atrasadas
                </v-card-title>
                <v-card-text>
                    <v-color-picker
                  dot-size="25"
                  swatches-max-height="200"
                  v-model="colors.atrasadas"
                  elevation="10"
                  mode="hexa"
                ></v-color-picker>
                </v-card-text>
              </v-card>

              <v-card>
                <v-card-title>
                  CTe retido
                </v-card-title>
                <v-card-text>
                    <v-color-picker
                  dot-size="25"
                  swatches-max-height="200"
                  v-model="colors.retido"
                  elevation="10"
                  mode="hexa"
                ></v-color-picker>
                </v-card-text>
              </v-card>

              <v-card>
                <v-card-title>
                  Com Ocorrência
                </v-card-title>
                <v-card-text>
                    <v-color-picker
                  dot-size="25"
                  swatches-max-height="200"
                  v-model="colors.ocorrencia"
                  elevation="10"
                  mode="hexa"
                ></v-color-picker>
                </v-card-text>
              </v-card>

              <v-card>
                <v-card-title>
                  Entregas Para Hoje
                </v-card-title>
                <v-card-text>
                    <v-color-picker
                  dot-size="25"
                  swatches-max-height="200"
                  v-model="colors.hoje"
                  elevation="10"
                  mode="hexa"
                ></v-color-picker>
                </v-card-text>
              </v-card>

              <v-card>
                <v-card-title>
                  Em Rota
                </v-card-title>
                <v-card-text>
                    <v-color-picker
                  dot-size="25"
                  swatches-max-height="200"
                  v-model="colors.rota"
                  elevation="10"
                  mode="hexa"
                ></v-color-picker>
                </v-card-text>
              </v-card>

            </div>

            <v-btn class="my-2" color="primary" block @click="save">Salvar</v-btn>

          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        colors: {
          ok: this.$store.state.colors.ok,
          atrasadas: this.$store.state.colors.atrasadas,
          retido: this.$store.state.colors.retido,
          hoje: this.$store.state.colors.hoje,
          ocorrencia: this.$store.state.colors.ocorrencia,
          rota: this.$store.state.colors.rota,
        }
      }
    },
    methods: {
      save() {
        this.$store.dispatch('set_colors', this.colors);
        this.$side({
          type: 'success',
          msg: 'Salvo!',
          duration: 4000
        });
      }
    },
  }
</script>

<style scoped>

</style>